import DashboardLayout from "components/Layouts/DashboardLayout";
import {useEffect, useState} from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ResiDashboard from "pages/ResiDashboard";
import StaticProxies from "pages/StaticProxies";
import Login from "pages/Login/Login";
import RegisterLayout from "components/Layouts/RegisterLayout/RegisterLayout";
import SelectMembership from "pages/SelectMembership";
import Cookies from 'universal-cookie';
import HybridProxies from "./pages/HybridProxies";

const App = () => {

  const cookies = new Cookies();
  const pathname = useLocation();
  const [referral, setReferral] = useState(null);
  
  useEffect(() => {
    window.scroll({ top: 0 });
  }, [pathname]);
    
    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        window.addEventListener('Rewardful.initialized', function () {
            setReferral(window.Rewardful.referral)
        })
    }, []);

  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path='/resi-dashboard' element={<ResiDashboard cookies={cookies} />} />
        <Route path='/static-proxies' element={<StaticProxies cookies={cookies}/>} />\
          <Route path='/hybrid-proxies' element={<HybridProxies cookies={cookies} />} />
        <Route path='/membership' element={<SelectMembership cookies={cookies} />} />
      </Route>

      <Route element={<RegisterLayout />}>
        <Route path='/' element={<Login cookies = {cookies} />} />
      </Route>
    </Routes>
  );
};

export default App;
