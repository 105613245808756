import React, { useState, useEffect } from "react";
import Styles from  './SelectMembership.module.scss';
import { icon_items } from './icon-item';
import AnimationButton from "components/UI/AnimationButton";
import Loading from "pages/Loading";

const SelectMembership = ({ cookies }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [membershipType, setMembershipType] = useState(false);

  useEffect(() => {
    const accessToken = cookies.get('access_token');
    fetch('https://dashboard-api.lemonclub.io/api/v1/fetchMembershipStatus', {
      method: "POST",
      body: JSON.stringify({
        "discord_token": `${accessToken}`
      })
    })
      .then(response => {
        return response.text()
      })
      .then(data => {
        setMembershipType(data !== "free")
        setIsLoading(false);
      })
  }, []);

  const membershipLink = (e) => {
    e.preventDefault();
    window.location.href = 'https://whop.com/checkout/Premium?pageRoute=lemonclub-sneakers-proxies-hub&d2c=true'
  }

  const freeLink = (e) => {
    e.preventDefault();
    window.location.href = 'https://whop.com/'
  }

  const freeList = [
    {label:"LemonLabs Plan at 5.90$/GB.", check:true},
    {label:"LemonPrime Plan at 2.75$/GB.", check:true},
    {label:"Residential Proxy Providers at Retail.", check:true},
  ];

  const premiumList = [
    "LemonLabs Plan at 3.90$/GB.",
    "LemonPrime Plan at 2.5$/GB",
    "Residential Proxy Providers at Under-Retail.",
  ];

  return (
    <div className={Styles.membership}>
        <div className={Styles.membership}>
          <div className={Styles.membershipContainer}>
            <div className={Styles.membershipHeading}>
              Select your Membership 
            </div>

            <div className={Styles.membershipText}>
              Lorem ipsum dolor sit amet consectetur. Tempus eu metus arcu enim porttitor felis lacinia sed.z
            </div>

            <div className={Styles.membershipContent}>
              <div className={`${Styles.membershipCard} ${!membershipType && Styles.membershipIsSelected}`}>
                <div className={Styles.heading}>
                  <div className={Styles.icon}>
                    {icon_items.free}
                  </div>
                  <span className={Styles.label}>
                    Free Plan
                  </span>
                </div>
                <div className={Styles.lists}>
                  {freeList.map((item, index) => (
                    <div className={Styles.listItem}>
                      <div className={Styles.listItemImg}>
                        {item.check ? icon_items.checkCircle : icon_items.minusCircle} 
                      </div>
                      <span className={`main-text ${Styles.listText}`}>{item.label}</span>
                    </div>
                  ))}
                </div>
                <div className={Styles.button}>
                    <AnimationButton
                        width="180px"
                        height="42px"
                        background={membershipType ? "linear-gradient(316deg, #FFA329 4.43%, #FE7B1C 56.59%, #FC2A01 150.36%), linear-gradient(90deg, #474EEB 0%, #7177EC 100%), #212121" : "#D6D6D6"}
                        color={membershipType ? "#FFF" : "black" }
                        size="16px"
                        borderRadius="12px"
                        label={!membershipType ? "Selected" : "Select"}
                        button={!membershipType ? "Selected" : "yellow"}
                        handler={membershipType ? freeLink : null}
                    />
                </div>
              </div>

              <div className={`${Styles.membershipCard} ${membershipType && Styles.membershipIsSelected} ${Styles.right}`}>
                <div className={Styles.heading}>
                  <div className={Styles.icon}>
                    {icon_items.premium}
                  </div>
                  <span className={Styles.label}>
                    Premium Plan
                  </span>
                </div>
                <div className={Styles.premiumLabel}>
                    <div className="main-text"> From </div>
                    <div className={Styles.monthly}>$9.99/<span style={{color: '#FFA329'}}>month</span></div>
                </div>
                <div className={Styles.lists}>
                  {premiumList.map((item, index) => (
                    <div className={Styles.listItem}>
                      <div className={Styles.listItemImg}>
                        {icon_items.checkCircle} 
                      </div>
                      <span className={`main-text ${Styles.listText}`}>{item}</span>
                    </div>
                  ))}
                </div>
                <div className={Styles.button}>
                    <AnimationButton
                        width="180px"
                        height="42px"
                        background={!membershipType ? "linear-gradient(316deg, #FFA329 4.43%, #FE7B1C 56.59%, #FC2A01 150.36%), linear-gradient(90deg, #474EEB 0%, #7177EC 100%), #212121" : "#D6D6D6"}
                        color={!membershipType ? "#FFF" : "black" }
                        size="16px"
                        borderRadius="12px"
                        label={membershipType ? "Selected" : "Select"}
                        button={membershipType ? "selected" : "yellow"}
                        handler={!membershipType ? membershipLink : null}
                    />
                </div>
                <div className={Styles.popular}>
                    Popular
                </div>
              </div>
            </div>

            <div className={Styles.membershipFooter}>
              Looking for something else? Contact us
              <span>here</span>
            </div>
          </div>
         
        </div>
        {isLoading ? ( <Loading/>) : ("")}
    </div>
  );
};

export default SelectMembership;
