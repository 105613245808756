import React, {useState, useEffect} from "react";
import Styles from "./HybridProxies.module.scss";
import Purchase from "../../components/Layouts/purchase";
import Loading from "pages/Loading";
import Order from "components/Layouts/hybrid-orders";
import Hybrid from "../../components/Layouts/hybrid";

const StaticProxies = (props) => {
    //Short
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState({});
    const [discordEmail, setDiscordEmail] = useState('');
    const [membershipType, setMembershipType] = useState('');
    const {cookies} = props;

    //setMembershipType
    useEffect(() => {
        const accessToken = cookies.get('access_token');
        fetch('https://dashboard-api.lemonclub.io/api/v1/fetchMembershipStatus', {
            method: "POST",
            body: JSON.stringify({
                "discord_token": `${accessToken}`
            })
        })
            .then(response => {
                return response.text()
            })
            .then(data => {
                setMembershipType(data)
            })
    }, []);
    
    useEffect(() => {
        const fragment = new URLSearchParams(window.location.hash.slice(1));
        const [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')];
    
        if (!accessToken) {
            setIsLoggedIn(false);
            return;
        }
    
        fetch('https://discord.com/api/users/@me', {
            method: "GET",
            headers: {
                authorization: `${tokenType} ${accessToken}`,
            },
        })
            .then(result => result.json())
            .then(response => {
                if (!response.username) {
                    setIsLoggedIn(false);
                    console.error('Error:', response.message)
                }
                setIsLoggedIn(true);
                cookies.set('access_token', accessToken, {path: '/', maxAge: "604800"});
                cookies.set('token_type', tokenType, {path: '/', maxAge: "604800"});
            })
            .then(() => {
                window.location = '/resi-dashboard';
            })
            .catch(console.error);
    }, []);
    
    useEffect(() => {
        const accessToken = cookies.get('access_token');
        if (!accessToken) {
            window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=1117499513792385216&response_type=token&redirect_uri=https%3A%2F%2Fnew-dashboard.lemonclub.io&scope=identify+email';
        } else if (discordEmail && membershipType) {
            fetch('https://dashboard-api.lemonclub.io/api/v1/checkExistingCustomer', {
                method: "POST",
                body: JSON.stringify({
                    "discord_token": `${accessToken}`,
                    "discord_email": `${discordEmail}`,
                    "membership_type": `${membershipType}`
                })
            });
        }
    }, [discordEmail, membershipType]);

    //new
    const tagList = [
        "Purchase",
        "Orders & Proxies",
    ]
    const [isLoading, setIsLoading] = useState(true);

    const [selectedTab, setSelectedTab] = useState(null);

    const changeTab = (selectedTab) => {
        if (selectedTab === 'Purchase') {
            document.getElementById("tab0").style.display = "block";
            document.getElementById("tab1").style.display = "none";
        } else if (selectedTab === 'Orders & Proxies') {
            document.getElementById("tab0").style.display = "none";
            document.getElementById("tab1").style.display = "block";
        }
        setSelectedTab(selectedTab);
    };

    function myFunction() {
        const tab0 = document.getElementById("tab0");
        const tab1 = document.getElementById("tab1");

        if (tab0 && tab1) {
            tab0.style.display = "block";
            tab1.style.display = "block";
        } else {
            console.log("there aren't these tabs.");
        }
    }

    useEffect(() => {
        const mediaQuery = window.matchMedia("(min-width: 781px)");

        function handleResize(e) {
            if (e.matches) {
                myFunction();
            }
        }

        mediaQuery.addEventListener("change", handleResize);

        if (mediaQuery.matches) {
            myFunction();
        }

        return () => {
            mediaQuery.removeEventListener("change", handleResize);
        };
    }, []);

    return (
        <div className={Styles.residential_container}>
            <div className={Styles.residential}>
                <span className={Styles.title}>Static Proxies</span>

                <div className={Styles.tab}>
                    {tagList.map((item) => (
                        <button onClick={() => changeTab(item)}
                                className={selectedTab === item ? Styles.active : ""}>{item}</button>
                    ))}
                </div>

                <div className={Styles.container}>
                    <div id="tab0" className={Styles.purchase}>
                        <Hybrid
                            cookies ={cookies}
                            user={user}
                            membershipType={membershipType}
                            setIsLoading = {setIsLoading}
                        />
                    </div>

                    <div id="tab1" className={Styles.order}>
                        <Order
                            cookies = {cookies}
                            // setIsLoading = {setIsLoading}
                        />
                    </div>
                </div>
                {isLoading ? (<Loading/>) : ("")}
            </div>
        </div>
    );
};

export default StaticProxies;

