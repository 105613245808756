import React, { useState } from 'react';
import ReactDOM from "react-dom";
import styles from "../ModalTopUp/ModalTopUp.module.scss";

const Modal = ({ children, show, variant, toggleHandler }) => {
    return ReactDOM.createPortal(
        <div style={{ position: "relative" }}>
            <div
                className={`${styles["popup-surface"]} ${
                    show && styles.show
                } ${variant}`}
            >
                {children}
            </div>
        </div>,
        document.getElementById("modal-root")
    );
};


export default Modal;
