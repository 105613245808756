import residential from "assets/images/side-residential.svg";
import s_icon from "assets/images/side-static.svg";
import h_icon from "assets/images/hybrid-proxy.svg";
import membership from "assets/images/side-membership.svg";

export const items = [
  {
    src: residential,
    alt: "side-residential",
    text: "Residential Proxies",
    route: "/resi-dashboard",
  },
  {
    src: s_icon,
    alt: "side-static",
    text: "Static-proxies",
    route: "/static-proxies",
  },
  {
    src: h_icon,
    alt: "side-static",
    text: "LemonLive",
    route: "/hybrid-proxies",
  },
  {
    src: membership,
    alt: "side-membership",
    text: "Membership",
    route: "/membership",
  }
];
