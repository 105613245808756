import React from "react";
import Styles from "./order.module.scss";
import {icon_items} from "../../../pages/ResiDashboard/icon-item";
import ProductList from "../hybrid-products";
import SearchInput from "components/UI/SearchInput";

const Order = (props) => {
    const { cookies } = props;

    return (
        <div className={`order-container ${Styles.order_container}`}>
            <div className={Styles.plan}>
                <div className={Styles.bland}>
                    <div className={Styles.bland_icon}>
                        {icon_items.analytics}

                        <span className={Styles.brand_title}>  Your Orders</span>
                    </div>

                    <div className={Styles.brand_description}>
                        Here you can see all of your static proxy orders.
                    </div>
                </div>     
                <div className={Styles.search}>
                    <span className={Styles.brand_label}>Search</span>
                    <SearchInput
                        background="var(--re-select-dark-bg)"
                    />
                </div>       
            </div>

            <ProductList cookies = {cookies} setIsLoading = {props.setIsLoading} />
        </div>
    );
};

export default Order;
